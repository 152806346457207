@import "$vars.styl"
@import "~bulma-stylus"

.columns.is-flex-mobile {
    @media screen and (max-width: 768px) {
        display: flex !important;
    }
}

.columns.is-flex-tablet {
    @media screen and (min-width: 769px), print {
        display: flex !important;
    }
}

.dev-only
    background-color rgba($primary, .1)

.navbar-item.is-tab.dev-only
    background-color rgba($primary, .1) !important
