@import '$vars.styl'
//@import '~font-awesome/css/font-awesome.css'

clearfix()
    zoom 1
    &:after
    &:before
        content ""
        display table
    &:after
        clear both

.clearfix
    clearfix()

.hero .hero-body
    padding: 1.5rem .5rem

.is-transparent
    background: transparent

.container.is-white
    background: $white

.navbar
    min-height: 20px
    z-index 10

    .navbar-divider
        +until($tablet)
            display block

    > .navbar-brand
        min-width: 5px

    &.navbar-mobile
        +from($desktop)
            display none

    +until($desktop)
        position fixed
        top 0
        right 0
        .navbar-menu
            min-width 75vw

        &.inline-mobile
            position relative

    +until($tablet)
        .navbar-menu .navbar-item.is-tab
            line-height 2em

            &.is-hover
                border-bottom none
                border-left 1px solid $primary

            &.is-active
                border-bottom none
                border-left 3px solid $primary
                background $primary
                color $primary-invert

.navbar > .navbar-menu > *
    flex-flow: row wrap

#main-nav
    z-index 11

#env
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    width: 100px
    font-size: 12px
    text-align: center
    border-radius: 0 0 20px 20px
    box-shadow: 1px 0 5px rgba(#000, .2)

    &.local
        background: $purple
        color: #eee

        &::after
            content: "local"

    &.staging
        background: $warning

        &::after
            content: "staging"

    &.production
        display: none

.is-boolean, .icon, .is-icon
    &.is-success, .is-success
        color: $success

    &.is-warning, .is-warning
        color: $warning

    &.is-danger, .is-danger
        color: $danger

    &.is-info, .is-info
        color: $info

    &.is-primary, .is-primary
        color: $primary

td &, th &
    .is-boolean, .icon, .is-icon
        text-align: center
        width: 1%

.icon:first-child:not(:last-child)
    //margin-left: calc(-0.375em - 1px)
    margin-right: 0.375em


td, th, span
    &.is-number
        text-align: right
        background-color: transparent !important
        border-color: $grey-lighter !important

        &.is-danger
            color: darken($danger, 30%)

        &.is-success
            color: darken($success, 30%)

.related-field, .date-picker-field
    position: relative

    input.hidden
        position: absolute
        top: 0
        left: 0
        opacity: 0
        z-index: -10
        pointer-events: none

/*table
    +until($desktop)
        &.is-responsive
            width: 100%
            border-collapse: collapse
            border-spacing: 0
            display: block
            position: relative

            td:empty:before
                content: '\00a0'

            th,
            td
                margin: 0
                vertical-align: top
                min-height: 3em

            th
                text-align: left

            thead
                border-right: solid 2px $grey-lighter
                display: block
                float: left

                tr
                    display: block
                    padding: 0 10px 0 0

                    th:empty::before
                        content: "\00a0"
                td,
                th
                    border-width: 0 0 1px

            tbody
                display: block
                width: auto
                position: relative
                overflow-x: auto
                white-space: nowrap

                tr
                    display: inline-block
                    vertical-align: top

            th
                display: block
                text-align: right

            td
                display: block
                //min-height: 1.25em
                text-align: left

            th,
            td
                &:last-child
                    border-bottom-width: 0

            tr
                &:last-child
                    td:not(:last-child)
                        border: $table-cell-border
                        border-width: $table-cell-border-width

            &.is-bordered
                td,
                th
                    border-width: 1px
                tr
                    td,
                    th
                        &:last-child
                            border-bottom-width: 1px
                    &:last-child
                        td,
                        th
                            border-width: $table-cell-border-width*/

.pagination
    margin: 0 0 20px 0

/* toast */
.vue-toast_container
    position: absolute
    padding-bottom: 10px
    transform: translateY(0)
    transition: transform .2s ease-out
    backface-visibility: hidden

    &._default .vue-toast_message,
    &._undefined .vue-toast_message
        background-color: #f5f5f5
        color: #4a4a4a

    &._info .vue-toast_message
        background-color: $info

    &._success .vue-toast_message
        background-color: $success

    &._warning .vue-toast_message
        background-color: $warning
        color: #4a4a4a

    &._error .vue-toast_message,
    &._danger .vue-toast_message
        background-color: $danger

.vue-toast-manager_container
    position: fixed
    width: 100%
    z-index: 9999

    &.__top
        top: 10px

        .vue-toast_container
            top: 0

    &.__bottom
        bottom: 10px

        .vue-toast_container
            bottom: 0

    &.__left
        left: 10px

        .vue-toast_container
            left: 0

    &.__right
        right: 10px

        .vue-toast_container
            right: 0

.vue-toast_message
    color: white
    padding: 1.25rem 2.5rem 1.25rem 1.5rem
    border-radius: $radius
    border: 1px solid rgba(#444, .1)
    box-shadow: 2px 2px 1px rgba(#444, .05)

.vue-toast_close-btn {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 14px;
    height: 14px;
    opacity: .7;
    transition: opacity .15s ease-in-out;
    backface-visibility: hidden;
}

.vue-toast_close-btn:hover {
    opacity: .9;
}

.vue-toast_close-btn::before, .vue-toast_close-btn::after {
    content: '';
    position: absolute;
    top: 6px;
    width: 14px;
    height: 2px;
    background-color: white;
}

.vue-toast_close-btn::before {
    transform: rotate(45deg);
}

.vue-toast_close-btn::after {
    transform: rotate(-45deg);
}

.vue-toast-enter-active
    opacity: 0
    transition: all .2s ease-out

.vue-toast-enter-to
    opacity: 1

.vue-toast-leave-active
    opacity: 1
    transition: all .1s ease-out

.vue-toast-leave-to
    opacity: 0

label.label:not(.is-required)
    font-weight: $weight-normal

a[disabled]
    pointer-events: none

    &.navbar-item
        color $grey-lighter

.help.is-larger
    font-size .95em

fieldset
    margin-bottom 20px
    padding 1.5em

.field.has-addons.has-label
    flex-wrap wrap

    label
        min-width 100%

.field
    .field-messages
        width 100%

    .multiselect--disabled
        cursor not-allowed

    .multiselect
        min-height 32px
        margin-bottom 10px
        border-radius $control-radius
        box-shadow $input-shadow

        &, .multiselect__input, .multiselect__single
            font-size $body-size

        .multiselect__select
            height: 28px

        .multiselect__tags
            background transparent
            min-height 32px
            padding 4px 40px 0 4px

        .multiselect__input, .multiselect__single
            margin-bottom 4px

        .multiselect__option
            padding: 8px 12px
            min-height 32px

            &::after
                line-height 30px

        .multiselect__placeholder
            padding-top 0
            padding-left 4px
            margin-bottom 6px

    &.empty-label
        padding-top 2.5rem

.multiselect--active //.multiselect__content-wrapper
    z-index 31 !important

form > [class*="vf-field"]
    &:not(:last-child)
        margin-bottom: 0.75rem


+until($desktop)
    .dashboard > .hero > .hero-body
        padding .5em

        .level
            > .level-left
                display none

            > .level-right
                margin-top 0

html.hamburgerOpen
    overflow hidden

    .navbar
        bottom 0
        overflow-y scroll

        .navbar-menu
            min-height calc(100vh - 3.25rem)

.is-nowrap
    white-space nowrap

.is-clickable
    cursor pointer

    &:hover
        .title,
        .subtitle
            text-decoration underline
            text-underline #444444

.has-link-icon
    position relative

    &::after
        content "\F35d"
        font-family $fontawesome
        font-weight 900
        position absolute
        right .5em
        top .5em

.vue-js-switch
    .v-switch-core
        background $grey-light

    .v-switch-label
        font-size 1rem
        font-weight 300

    &.toggled
        .v-switch-core
            background $success

.icon > [class*=ion-]
    font-size 1.5em

.table-wrap
    overflow hidden
    overflow-x auto
    max-width initial
    min-width initial

.overflow-visible
    overflow visible

    .modal&
        .modal-content
            overflow visible

div[data-popover=tooltip]{background:#666;color:#f9f9f9;font-size:12px;line-height:1.5;margin:5px; padding:5px 10px;}

